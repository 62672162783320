<template>
  <div class="supplier-verifications-table">
    <supplier-verifications-query
      :loading.sync="isLoadingVerifications"
      @data="onReceivedVerifications"
    />

    <supplier-verifications-search-tools-query
      @data="searchTools = $event.supplier.adsData.verificationsSearchTools"
    />

    <div class="flex flex-row border-top">
      <div class="flex-shrink-0">
        <collapsible-search-group-sidebar
          v-model="searchInput"
          :search-tools="searchTools || []"
          :is-loading="!searchTools"
          :filter="pager.filter"
          @search="pager = { ...pager, ...$event }"
        />
      </div>
      <div
        class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
      >
        <ads-mutation>
          <template v-slot="{ isSaving, exportSupplierAds }">
            <filter-toolbar
              class="items-end"
              placeholder="Search Verifications, Customers, etc."
              :is-exporting="isSaving"
              :value="pager.filter"
              @input="pager = { ...pager, filter: $event }"
              @export="onExport(exportSupplierAds)"
            />
          </template>
        </ads-mutation>

        <div class="relative">
          <el-table
            :data="(!isLoadingVerifications && verifications) || []"
            border
            class="w-full margin-top-xxl el-table-slim"
          >
            <table-empty-slot
              slot="empty"
              :is-loading="isLoadingVerifications"
              :has-data="!!verifications"
              :empty-message="emptyFilterMessage"
              error-message="There was a problem loading your verifications. Please try again later."
            />

            <el-table-column label="Status" align="center" width="100">
              <template slot-scope="{ row }">
                <status-icon :icons="AdStatusInfo" :status="row.status" />
              </template>
            </el-table-column>

            <el-table-column
              label="Run Date(s)"
              align="center"
              width="110"
              prop="start_date"
            >
              <template slot-scope="{ row }">
                <date-column
                  :start-date="row.start_date"
                  :end-date="row.end_date"
                />
              </template>
            </el-table-column>

            <el-table-column label="IO" align="center" width="100">
              <template v-slot="{ row }">
                <supplier-io-column
                  :order="row.order"
                  :campaign="row.campaign"
                  hide-review
                />
              </template>
            </el-table-column>

            <el-table-column label="Rep">
              <template slot-scope="{ row }">
                <rep-column :order="row.order" />
              </template>
            </el-table-column>

            <el-table-column label="Customer">
              <template slot-scope="{ row }">
                <customer-column
                  :customer="row.order.customer"
                  :campaign="row.campaign"
                />
              </template>
            </el-table-column>

            <el-table-column label="Ad Details">
              <template slot-scope="{ row }">
                <ad-column :ad="row" />
              </template>
            </el-table-column>

            <el-table-column label="Creative" align="center" width="220">
              <template slot-scope="{ row }">
                <creative-column
                  :ad="row"
                  :is-disabled="!$can('pub_manage_creative')"
                />
              </template>
            </el-table-column>

            <el-table-column label="Verification" align="center" width="220">
              <template slot-scope="{ row }">
                <supplier-verification-column
                  :ad="row"
                  :start-open="row.id === openAdId"
                  @close="openAdId = null"
                />
              </template>
            </el-table-column>

            <el-table-column label="Comments" min-width="35px" align="center">
              <template v-slot="{ row }">
                <supplier-notes-column :ads="[row]" :order="row.order" />
              </template>
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginatorInfo"
            :info="paginatorInfo"
            :pager.sync="pager"
            class="mt-5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { filePdf } from '@/vendor/icons';
import { AccountType, AdStatus, AdStatusInfo } from '@/constants';
import { date as formatDate } from '@/utils/filters';
import { download } from '@/vendor/download';

import {
  AdColumn,
  CreativeColumn,
  CustomerColumn,
  SupplierIoColumn,
  SupplierVerificationColumn
} from '@/components/Core/Table/Columns';

import AdsMutation from '@/components/Mutations/Ads/AdsMutation';
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierNotesColumn from '@/components/Supplier/Ads/SupplierNotesColumn';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import { SupplierVerificationsQuery } from '@/components/Queries';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import SupplierVerificationsSearchToolsQuery from '@/components/Queries/Supplier/Ads/SupplierVerificationsSearchToolsQuery';
import DateColumn from '@/components/Core/Table/Columns/DateColumn';
import RepColumn from '@/components/Core/Table/Columns/RepColumn';

export default {
  components: {
    RepColumn,
    DateColumn,
    SupplierVerificationsSearchToolsQuery,
    TableEmptySlot,
    AdColumn,
    AdsMutation,
    CollapsibleSearchGroupSidebar,
    CreativeColumn,
    CustomerColumn,
    FilterToolbar,
    Pagination,
    SupplierIoColumn,
    SupplierNotesColumn,
    SupplierVerificationColumn,
    SupplierVerificationsQuery,
    StatusIcon
  },

  data() {
    return {
      isLoadingVerifications: false,
      paginatorInfo: null,
      searchTools: null,
      verifications: null,
      searchInput: {},

      AccountType,
      AdStatus,
      AdStatusInfo,
      openAdId: null,

      // Icons
      filePdf
    };
  },

  computed: {
    pager: {
      get() {
        return this.$store.getters['verifications/pager'];
      },
      set(value) {
        this.$store.commit('verifications/setPager', value);
      }
    },
    emptyFilterMessage() {
      if (this.pager.filter.start_date) {
        let startDate = this.pager.filter.start_date['>='];
        let endDate = this.pager.filter.start_date['<='];

        let str =
          "You don't have any ads running between " + formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any ads :(";
      }
    }
  },

  created() {
    this.pager = {
      ...this.pager,
      sort: [{ column: 'start_date', order: 'descending' }],
      filter:
        this.$route.query && this.$route.query.filter
          ? this.$route.query.filter
          : {}
    };

    let filters = {};

    if (this.pager.filter.status) {
      filters['Verification Status'] = {
        searchValue: {
          status: this.pager.filter.status
        },
        value: Array.isArray(this.pager.filter.status)
          ? this.pager.filter.status
          : [this.pager.filter.status]
      };
    }

    this.searchInput = {
      filters
    };
  },

  methods: {
    showEndDate(ad) {
      let duration = moment(ad.end_date).diff(ad.start_date);

      return (
        ad.end_date && duration > moment.duration(1, 'day').asMilliseconds()
      );
    },
    onReceivedVerifications({ supplier: { adsData } }) {
      this.verifications = adsData.ads.data;
      this.paginatorInfo = adsData.ads.paginatorInfo;

      if (
        this.$route.params &&
        this.$route.params.filter &&
        this.$route.params.filter.supplierVerificationsFilter
      ) {
        this.openAdId = adsData.ads.data.find(
          a => a.id === this.$route.params.ad.id
        ).id;
      }
    },
    async onExport(exportSupplierAds) {
      const data = await exportSupplierAds(this.pager.filter);

      download(data.mutateAd.exportSupplierAds, 'Verifications.csv');
    }
  }
};
</script>

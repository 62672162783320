<template>
  <div id="page-supplier-verifications" class="page-content text-dark-gray">
    <the-page-header>
      <template slot="title">
        Verifications
      </template>
      <template slot="subtitle">
        Show your customers how their ads turned out. Upload a verification
        image for each order below.
      </template>
    </the-page-header>

    <supplier-verifications-table />
  </div>
</template>

<script>
import SupplierVerificationsTable from '@/components/Supplier/Verifications/SupplierVerificationsTable';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    SupplierVerificationsTable,
    ThePageHeader
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplier-verifications-table"},[_c('supplier-verifications-query',{attrs:{"loading":_vm.isLoadingVerifications},on:{"update:loading":function($event){_vm.isLoadingVerifications=$event},"data":_vm.onReceivedVerifications}}),_c('supplier-verifications-search-tools-query',{on:{"data":function($event){_vm.searchTools = $event.supplier.adsData.verificationsSearchTools}}}),_c('div',{staticClass:"flex flex-row border-top"},[_c('div',{staticClass:"flex-shrink-0"},[_c('collapsible-search-group-sidebar',{attrs:{"search-tools":_vm.searchTools || [],"is-loading":!_vm.searchTools,"filter":_vm.pager.filter},on:{"search":function($event){_vm.pager = Object.assign({}, _vm.pager, $event)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{staticClass:"flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"},[_c('ads-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var exportSupplierAds = ref.exportSupplierAds;
return [_c('filter-toolbar',{staticClass:"items-end",attrs:{"placeholder":"Search Verifications, Customers, etc.","is-exporting":isSaving,"value":_vm.pager.filter},on:{"input":function($event){_vm.pager = Object.assign({}, _vm.pager, {filter: $event})},"export":function($event){return _vm.onExport(exportSupplierAds)}}})]}}])}),_c('div',{staticClass:"relative"},[_c('el-table',{staticClass:"w-full margin-top-xxl el-table-slim",attrs:{"data":(!_vm.isLoadingVerifications && _vm.verifications) || [],"border":""}},[_c('table-empty-slot',{attrs:{"slot":"empty","is-loading":_vm.isLoadingVerifications,"has-data":!!_vm.verifications,"empty-message":_vm.emptyFilterMessage,"error-message":"There was a problem loading your verifications. Please try again later."},slot:"empty"}),_c('el-table-column',{attrs:{"label":"Status","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('status-icon',{attrs:{"icons":_vm.AdStatusInfo,"status":row.status}})]}}])}),_c('el-table-column',{attrs:{"label":"Run Date(s)","align":"center","width":"110","prop":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('date-column',{attrs:{"start-date":row.start_date,"end-date":row.end_date}})]}}])}),_c('el-table-column',{attrs:{"label":"IO","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('supplier-io-column',{attrs:{"order":row.order,"campaign":row.campaign,"hide-review":""}})]}}])}),_c('el-table-column',{attrs:{"label":"Rep"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('rep-column',{attrs:{"order":row.order}})]}}])}),_c('el-table-column',{attrs:{"label":"Customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customer-column',{attrs:{"customer":row.order.customer,"campaign":row.campaign}})]}}])}),_c('el-table-column',{attrs:{"label":"Ad Details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ad-column',{attrs:{"ad":row}})]}}])}),_c('el-table-column',{attrs:{"label":"Creative","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('creative-column',{attrs:{"ad":row,"is-disabled":!_vm.$can('pub_manage_creative')}})]}}])}),_c('el-table-column',{attrs:{"label":"Verification","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('supplier-verification-column',{attrs:{"ad":row,"start-open":row.id === _vm.openAdId},on:{"close":function($event){_vm.openAdId = null}}})]}}])}),_c('el-table-column',{attrs:{"label":"Comments","min-width":"35px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('supplier-notes-column',{attrs:{"ads":[row],"order":row.order}})]}}])})],1),(_vm.paginatorInfo)?_c('pagination',{staticClass:"mt-5",attrs:{"info":_vm.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }